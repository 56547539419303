import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _78e346bc = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _51f204a4 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _0d5f5a97 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _ad6aa5ce = () => interopDefault(import('../pages/user/index.vue' /* webpackChunkName: "pages/user/index" */))
const _2443a7a5 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _2248ceee = () => interopDefault(import('../pages/career.vue' /* webpackChunkName: "pages/career" */))
const _25ebd422 = () => interopDefault(import('../pages/magazine/index.vue' /* webpackChunkName: "pages/magazine/index" */))
const _44294204 = () => interopDefault(import('../pages/registration.vue' /* webpackChunkName: "pages/registration" */))
const _24b1005c = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _1b4f976e = () => interopDefault(import('../pages/editors/index.vue' /* webpackChunkName: "pages/editors/index" */))
const _3b4ef5f2 = () => interopDefault(import('../pages/products/index.vue' /* webpackChunkName: "pages/products/index" */))
const _675ce512 = () => interopDefault(import('../pages/whispers-of-wood.vue' /* webpackChunkName: "pages/whispers-of-wood" */))
const _e3f8776e = () => interopDefault(import('../pages/checkout/successful-order.vue' /* webpackChunkName: "pages/checkout/successful-order" */))
const _75994b5e = () => interopDefault(import('../pages/user/favourite-products.vue' /* webpackChunkName: "pages/user/favourite-products" */))
const _9137fb58 = () => interopDefault(import('../pages/checkout/shipping-and-payment-details.vue' /* webpackChunkName: "pages/checkout/shipping-and-payment-details" */))
const _32a4fb8e = () => interopDefault(import('../pages/checkout/cart.vue' /* webpackChunkName: "pages/checkout/cart" */))
const _902a3cb0 = () => interopDefault(import('../pages/checkout/summary.vue' /* webpackChunkName: "pages/checkout/summary" */))
const _c3fe15ce = () => interopDefault(import('../pages/checkout/personal-data.vue' /* webpackChunkName: "pages/checkout/personal-data" */))
const _198dfdf5 = () => interopDefault(import('../pages/editors/star-map.vue' /* webpackChunkName: "pages/editors/star-map" */))
const _26b22ff8 = () => interopDefault(import('../pages/editors/map.vue' /* webpackChunkName: "pages/editors/map" */))
const _357f0462 = () => interopDefault(import('../pages/user/forgotten-password/_id.vue' /* webpackChunkName: "pages/user/forgotten-password/_id" */))
const _0c18a31a = () => interopDefault(import('../pages/payment-failed/_id.vue' /* webpackChunkName: "pages/payment-failed/_id" */))
const _5f06a112 = () => interopDefault(import('../pages/payment-successful/_id.vue' /* webpackChunkName: "pages/payment-successful/_id" */))
const _2ce873d2 = () => interopDefault(import('../pages/magazine/_article.vue' /* webpackChunkName: "pages/magazine/_article" */))
const _17c4b592 = () => interopDefault(import('../pages/order-failed/_id.vue' /* webpackChunkName: "pages/order-failed/_id" */))
const _62c4b3e2 = () => interopDefault(import('../pages/payment-feedback/_id.vue' /* webpackChunkName: "pages/payment-feedback/_id" */))
const _ad6d6280 = () => interopDefault(import('../pages/products/_category/index.vue' /* webpackChunkName: "pages/products/_category/index" */))
const _2a6ac5ef = () => interopDefault(import('../pages/order-successful/_id.vue' /* webpackChunkName: "pages/order-successful/_id" */))
const _82625996 = () => interopDefault(import('../pages/products/_category/_product/index.vue' /* webpackChunkName: "pages/products/_category/_product/index" */))
const _0ba8b94f = () => interopDefault(import('../pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/adatvedelmi-nyilatkozat",
    component: _78e346bc,
    name: "privacy-policy___hu___default"
  }, {
    path: "/bejelentkezes",
    component: _51f204a4,
    name: "login___hu___default"
  }, {
    path: "/en",
    component: _0d5f5a97,
    name: "index___en"
  }, {
    path: "/felhasznalo",
    component: _ad6aa5ce,
    name: "user___hu___default"
  }, {
    path: "/hu",
    component: _0d5f5a97,
    name: "index___hu"
  }, {
    path: "/kapcsolat",
    component: _2443a7a5,
    name: "contact___hu___default"
  }, {
    path: "/karrier",
    component: _2248ceee,
    name: "career___hu___default"
  }, {
    path: "/magazin",
    component: _25ebd422,
    name: "magazine___hu___default"
  }, {
    path: "/regisztracio",
    component: _44294204,
    name: "registration___hu___default"
  }, {
    path: "/rolunk",
    component: _24b1005c,
    name: "about___hu___default"
  }, {
    path: "/szerkesztok",
    component: _1b4f976e,
    name: "editors___hu___default"
  }, {
    path: "/termekek",
    component: _3b4ef5f2,
    name: "products___hu___default"
  }, {
    path: "/whispers-of-wood",
    component: _675ce512,
    name: "whispers-of-wood___hu___default"
  }, {
    path: "/checkout/successful-order",
    component: _e3f8776e,
    name: "checkout-successful-order___hu___default"
  }, {
    path: "/en/about",
    component: _24b1005c,
    name: "about___en"
  }, {
    path: "/en/career",
    component: _2248ceee,
    name: "career___en"
  }, {
    path: "/en/contact",
    component: _2443a7a5,
    name: "contact___en"
  }, {
    path: "/en/editors",
    component: _1b4f976e,
    name: "editors___en"
  }, {
    path: "/en/login",
    component: _51f204a4,
    name: "login___en"
  }, {
    path: "/en/magazine",
    component: _25ebd422,
    name: "magazine___en"
  }, {
    path: "/en/privacy-policy",
    component: _78e346bc,
    name: "privacy-policy___en"
  }, {
    path: "/en/products",
    component: _3b4ef5f2,
    name: "products___en"
  }, {
    path: "/en/registration",
    component: _44294204,
    name: "registration___en"
  }, {
    path: "/en/user",
    component: _ad6aa5ce,
    name: "user___en"
  }, {
    path: "/en/whispers-of-wood",
    component: _675ce512,
    name: "whispers-of-wood___en"
  }, {
    path: "/felhasznalo/kedvenc-termekek",
    component: _75994b5e,
    name: "user-favourite-products___hu___default"
  }, {
    path: "/hu/adatvedelmi-nyilatkozat",
    component: _78e346bc,
    name: "privacy-policy___hu"
  }, {
    path: "/hu/bejelentkezes",
    component: _51f204a4,
    name: "login___hu"
  }, {
    path: "/hu/felhasznalo",
    component: _ad6aa5ce,
    name: "user___hu"
  }, {
    path: "/hu/kapcsolat",
    component: _2443a7a5,
    name: "contact___hu"
  }, {
    path: "/hu/karrier",
    component: _2248ceee,
    name: "career___hu"
  }, {
    path: "/hu/magazin",
    component: _25ebd422,
    name: "magazine___hu"
  }, {
    path: "/hu/regisztracio",
    component: _44294204,
    name: "registration___hu"
  }, {
    path: "/hu/rolunk",
    component: _24b1005c,
    name: "about___hu"
  }, {
    path: "/hu/szerkesztok",
    component: _1b4f976e,
    name: "editors___hu"
  }, {
    path: "/hu/termekek",
    component: _3b4ef5f2,
    name: "products___hu"
  }, {
    path: "/hu/whispers-of-wood",
    component: _675ce512,
    name: "whispers-of-wood___hu"
  }, {
    path: "/rendeles/fizetes-es-kiszallitas",
    component: _9137fb58,
    name: "checkout-shipping-and-payment-details___hu___default"
  }, {
    path: "/rendeles/kosar",
    component: _32a4fb8e,
    name: "checkout-cart___hu___default"
  }, {
    path: "/rendeles/megrendeles-osszegzese",
    component: _902a3cb0,
    name: "checkout-summary___hu___default"
  }, {
    path: "/rendeles/szemelyes-adatok",
    component: _c3fe15ce,
    name: "checkout-personal-data___hu___default"
  }, {
    path: "/szerkesztok/csillagterkep",
    component: _198dfdf5,
    name: "editors-star-map___hu___default"
  }, {
    path: "/szerkesztok/terkep-szerkeszto",
    component: _26b22ff8,
    name: "editors-map___hu___default"
  }, {
    path: "/en/checkout/checkout",
    component: _32a4fb8e,
    name: "checkout-cart___en"
  }, {
    path: "/en/checkout/personal-data",
    component: _c3fe15ce,
    name: "checkout-personal-data___en"
  }, {
    path: "/en/checkout/shipping-and-payment-details",
    component: _9137fb58,
    name: "checkout-shipping-and-payment-details___en"
  }, {
    path: "/en/checkout/successful-order",
    component: _e3f8776e,
    name: "checkout-successful-order___en"
  }, {
    path: "/en/checkout/summary",
    component: _902a3cb0,
    name: "checkout-summary___en"
  }, {
    path: "/en/editors/map-editor",
    component: _26b22ff8,
    name: "editors-map___en"
  }, {
    path: "/en/editors/star-map-editor",
    component: _198dfdf5,
    name: "editors-star-map___en"
  }, {
    path: "/en/user/favourite-products",
    component: _75994b5e,
    name: "user-favourite-products___en"
  }, {
    path: "/hu/checkout/successful-order",
    component: _e3f8776e,
    name: "checkout-successful-order___hu"
  }, {
    path: "/hu/felhasznalo/kedvenc-termekek",
    component: _75994b5e,
    name: "user-favourite-products___hu"
  }, {
    path: "/hu/rendeles/fizetes-es-kiszallitas",
    component: _9137fb58,
    name: "checkout-shipping-and-payment-details___hu"
  }, {
    path: "/hu/rendeles/kosar",
    component: _32a4fb8e,
    name: "checkout-cart___hu"
  }, {
    path: "/hu/rendeles/megrendeles-osszegzese",
    component: _902a3cb0,
    name: "checkout-summary___hu"
  }, {
    path: "/hu/rendeles/szemelyes-adatok",
    component: _c3fe15ce,
    name: "checkout-personal-data___hu"
  }, {
    path: "/hu/szerkesztok/csillagterkep",
    component: _198dfdf5,
    name: "editors-star-map___hu"
  }, {
    path: "/hu/szerkesztok/terkep-szerkeszto",
    component: _26b22ff8,
    name: "editors-map___hu"
  }, {
    path: "/en/user/forgotten-password/:id",
    component: _357f0462,
    name: "user-forgotten-password-id___en"
  }, {
    path: "/hu/felhasznalo/elfelejtett-jelszo/:id",
    component: _357f0462,
    name: "user-forgotten-password-id___hu"
  }, {
    path: "/en/_failed-payment/:id",
    component: _0c18a31a,
    name: "payment-failed-id___en"
  }, {
    path: "/en/_successful-payment/:id",
    component: _5f06a112,
    name: "payment-successful-id___en"
  }, {
    path: "/en/magazine/:article",
    component: _2ce873d2,
    name: "magazine-article___en"
  }, {
    path: "/en/order-failed/:id",
    component: _17c4b592,
    name: "order-failed-id___en"
  }, {
    path: "/en/order-feedback/:id",
    component: _62c4b3e2,
    name: "payment-feedback-id___en"
  }, {
    path: "/en/products/:category",
    component: _ad6d6280,
    name: "products-category___en"
  }, {
    path: "/en/successful-order/:id",
    component: _2a6ac5ef,
    name: "order-successful-id___en"
  }, {
    path: "/felhasznalo/elfelejtett-jelszo/:id",
    component: _357f0462,
    name: "user-forgotten-password-id___hu___default"
  }, {
    path: "/hu/fizetes-visszaigazolas/:id",
    component: _62c4b3e2,
    name: "payment-feedback-id___hu"
  }, {
    path: "/hu/magazin/:article",
    component: _2ce873d2,
    name: "magazine-article___hu"
  }, {
    path: "/hu/sikeres-fizetes/:id",
    component: _5f06a112,
    name: "payment-successful-id___hu"
  }, {
    path: "/hu/sikeres-rendeles/:id",
    component: _2a6ac5ef,
    name: "order-successful-id___hu"
  }, {
    path: "/hu/sikertelen-fizetes/:id",
    component: _0c18a31a,
    name: "payment-failed-id___hu"
  }, {
    path: "/hu/sikertelen-rendeles/:id",
    component: _17c4b592,
    name: "order-failed-id___hu"
  }, {
    path: "/hu/termekek/:category",
    component: _ad6d6280,
    name: "products-category___hu"
  }, {
    path: "/en/products/:category/:product",
    component: _82625996,
    name: "products-category-product___en"
  }, {
    path: "/hu/termekek/:category/:product",
    component: _82625996,
    name: "products-category-product___hu"
  }, {
    path: "/en/:slug",
    component: _0ba8b94f,
    name: "slug___en"
  }, {
    path: "/fizetes-visszaigazolas/:id",
    component: _62c4b3e2,
    name: "payment-feedback-id___hu___default"
  }, {
    path: "/hu/:slug",
    component: _0ba8b94f,
    name: "slug___hu"
  }, {
    path: "/magazin/:article",
    component: _2ce873d2,
    name: "magazine-article___hu___default"
  }, {
    path: "/sikeres-fizetes/:id",
    component: _5f06a112,
    name: "payment-successful-id___hu___default"
  }, {
    path: "/sikeres-rendeles/:id",
    component: _2a6ac5ef,
    name: "order-successful-id___hu___default"
  }, {
    path: "/sikertelen-fizetes/:id",
    component: _0c18a31a,
    name: "payment-failed-id___hu___default"
  }, {
    path: "/sikertelen-rendeles/:id",
    component: _17c4b592,
    name: "order-failed-id___hu___default"
  }, {
    path: "/termekek/:category",
    component: _ad6d6280,
    name: "products-category___hu___default"
  }, {
    path: "/termekek/:category/:product",
    component: _82625996,
    name: "products-category-product___hu___default"
  }, {
    path: "/",
    component: _0d5f5a97,
    name: "index___hu___default"
  }, {
    path: "/:slug",
    component: _0ba8b94f,
    name: "slug___hu___default"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
