export const state = () => ({
    exampleData: {
        en: [],
        hu: [],
    },
    comparedProducts: [],
    customizedProducts: [],
});

export const mutations = {
    setExampleData(state, payload) {
        state.exampleData[payload.lang] = payload.data;
    },
    setComparedProducts(state, payload) {
        state.comparedProducts.push(payload);
    },
    storeCustomizedProductDetails(state, payload) {
        const existingIndex = state.customizedProducts.findIndex((product) => product.id === payload.id);

        if (existingIndex !== -1) {
            state.customizedProducts.splice(existingIndex, 1, payload);
        } else {
            state.customizedProducts.push(payload);
        }
    },
    removeComparedProducts(state, payload) {
        state.comparedProducts = state.comparedProducts.filter((el) => el !== payload);
    },
};
