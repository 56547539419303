<template>
    <div class="mb-10 flex flex-col justify-center pb-8 mt-14 border-b border-black border-dashed border-opacity-20">
        <h2
            class="text-[1.6rem] lg:text-4xl font-Inter-Bold"
            :class="{
                'lg:text-[2.25rem] mb-1': xl && !lg && !md,
                'lg:text-[2.25rem]': lg && !md,
                'lg:text-3xl': !lg && !md,
                'text-left lg:text-2xl': md,
                'text-left': alignLeft,
                'text-center': !alignLeft,
            }"
        >
            {{ title }}
        </h2>
        <h3
            v-if="subtitle"
            class="text-center text-sm lg:text-[17px] 3xl:text-xl mt-5 w-full lg:w-7/12 lg:mx-auto font-Inter-Regular opacity-50 leading-normal"
        >
            {{ subtitle }}
        </h3>
    </div>
</template>

<script>
export default {
    name: 'SectionIntro',
    props: {
        title: {
            type: String,
            required: true,
        },
        subtitle: {
            type: String,
            required: false,
            default: null,
        },
        md: {
            type: Boolean,
            required: false,
            default: false,
        },
        alignLeft: {
            type: Boolean,
            required: false,
            default: false,
        },
        lg: {
            type: Boolean,
            required: false,
            default: false,
        },
        xl: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
};
</script>
